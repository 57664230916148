import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import Settings from '../../../constants/company/settings';
import hubspotSettings from "../../../constants/company/settings";
import Cookies from "js-cookie";
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../../../components/locales/custom-localized-link";
import iconConsult from '../../../images/shared/icon-consult.png';
import iconReceive from '../../../images/shared/icon-recieve.png';
import iconSave from '../../../images/shared/icon-save.png';
import smallHr from '../../../images/shared/small-blue-hr.png';

const IndexPage = (props) => {
  const isBrowser = typeof window !== "undefined";
  const {t} = useTranslation();
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const [isSupport, setIsSupport] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    message: '',
    department: hubspotSettings.SUPPORT_EMAIL
  });
  const [showModal, setShowModal] = useState(false);
  const firstNameInputRef = React.useRef(null);
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
  };
  const isFormValid = formData.email != null && formData.email.trim().length > 0;
  const submit = (e) => {
    e.preventDefault();
    const postSubmit = hubspotSettings.CONTACT_FORM_UPGRADER;
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const body = {

      fields: [
        {
          name: 'firstname',
          value: formData.firstName,
        },
        {
          name: 'lastname',
          value: formData.lastName,
        },
        {
          name: 'company',
          value: formData.companyName,
        },
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'phone',
          value: formData.phoneNumber,
        },
        {
          name: 'message',
          value: formData.message,
        },
        {
          name: 'leadsource',
          value: 'Website',
        }
      ],

      context: {
        hutk,
        pageUri,
        pageName,
      },
    }

    fetch(postSubmit, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
    })
        // .then(res => res.json())
        .then((res) => {

          handleSubmitSuccess();
        })
        .catch(err => {
          alert(err)
        })
  };
  let handleSubmitSuccess = () => {
    if (context.language !== 'en') {
      window.location.href = '/' + context.language + '/support/upgrader-program/thank-you/';
    } else {
      window.location.href = '/support/upgrader-program/thank-you/';
    }
  }

  function handleScrollTo(elmID) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById(elmID).offsetTop - 270
    });
  }

  useEffect(() => {
    localStorage.setItem('UPGRADE_MODAL', 'seen');
  }, []);

  return (
      <Layout>
        <Seo
            title={t('digilock_upgrader_program')}
            description={t('des_digilock_upgrader_program')}
        />
        <div className="upgrade-program">
          <section className={'lock-solutions'}>
            <div className="container">
              <h1 dangerouslySetInnerHTML={{__html: t('upgrader_headline')}}/>
              <p><Trans>upgrader_para1</Trans></p>

              <CustomLocalizedLink
                  label={t('upgrader_CTA')}
                  goto={'#form'}
                  cls={'btn btn--orange page-brochure-button book-consultation'}
                  onClick={() => {handleScrollTo('supportForm')}}
              />
            </div>
          </section>
          <section className={'upgrade-advantage'}>
            <div class="container">
              <div>
                <StaticImage
                    src="../../../images/shared/digilock-lineup.png"
                    loading={'lazy'}
                    width={696}
                    height={175}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt="Digilock Lock Lineup"
                />
                <h2>
                  <Trans>upgrader_list1_headline</Trans>
                </h2>
                  <p dangerouslySetInnerHTML={{__html: t('upgrader_list1_1')}}/>
                  <p><Trans>upgrader_list1_2</Trans></p>
                  <p><Trans>upgrader_list1_3</Trans></p>
                  <p><Trans>upgrader_list1_4</Trans></p>

                <div className="delimeter"/>
              </div>
            </div>
          </section>
          <section className={'section-collapse'}>
            <div />
          </section>
          <section className={'simple-savings'}>
            <div class="container">
              <h2>
                <Trans>upgrader_list2_headline</Trans>
              </h2>
                <div className={'simple-savings-list'}>
                  <div>
                    <img src={iconConsult} width={'119'} height={'119'} alt="Purchase Icon"/>
                    <h3><Trans>consult</Trans></h3>
                    <span dangerouslySetInnerHTML={{__html:t('upgrader_list2_1')}} />
                  </div>
                  <div>
                    <img src={iconSave} width={'119'} height={'119'} alt="Return Icon"/>
                    <h3><Trans>save</Trans></h3>
                    <span dangerouslySetInnerHTML={{__html:t('upgrader_list2_2')}} />
                  </div>
                  <div>
                    <img src={iconReceive} width={'119'} height={'119'} alt="save Icon"/>
                    <h3><Trans>receive</Trans></h3>
                    <span dangerouslySetInnerHTML={{__html:t('upgrader_list2_3')}} />
                  </div>
                </div>
            </div>
          </section>
          <section className={'section-collapse'}>
            <div />
          </section>
          <section className="contact-info">
            <div className="container">
              <h2><Trans>upgrader_CTA</Trans></h2>
              <div className="sales-form show">
                <div className="container">
                  <form id={'supportForm'}
                        action="#"
                        className={'sales-contact-form'}
                        onSubmit={submit}
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                  >
                    <div className="row">
                      <div className="input-container first-name">
                        <label htmlFor={'firstName'}><Trans>first_name</Trans><span
                            style={{fontSize: 'x-small'}}> (required)</span></label>
                        <input type="text"
                               name="firstName"
                               id={'firstName'}
                               value={formData.firstName}
                               onChange={e => setFormData({...formData, firstName: e.target.value})}
                               required={'required'}
                               ref={firstNameInputRef}
                        />
                      </div>
                      <div className="input-container last-name">
                        <label htmlFor={'lastName'}><Trans>last_name</Trans><span
                            style={{fontSize: 'x-small'}}> (required)</span></label>
                        <input type="text"
                               name="lastName"
                               id={'lastName'}
                               value={formData.lastName}
                               required={'required'}
                               onChange={e => setFormData({...formData, lastName: e.target.value})}
                        />
                      </div>
                    </div>
                    <div className="input-container company-name">
                      <label htmlFor={'companyName'}><Trans>company_name</Trans><span
                          style={{fontSize: 'x-small'}}> (required)</span></label>
                      <input type="text"
                             name="companyName"
                             id={'companyName'}
                             value={formData.companyName}
                             required={'required'}
                             onChange={e => setFormData({...formData, companyName: e.target.value})}
                      />
                    </div>

                    <div className="row">
                      <div className="input-container email">
                        <label htmlFor={'email'}><Trans>email</Trans><span
                            style={{fontSize: 'x-small'}}> (required)</span></label>
                        <input type="email"
                               name="email"
                               id={'email'}
                               value={formData.email}
                               required={'required'}
                               onChange={e => setFormData({...formData, email: e.target.value})}
                        />
                      </div>
                      <div className="input-container phone-number">
                        <label htmlFor={'phoneNumber'}><Trans>phone_number</Trans></label>
                        <input type="text"
                               id={'phoneNumber'}
                               name="phoneNumber"
                               value={formData.phoneNumber}
                               onChange={e => setFormData({...formData, phoneNumber: e.target.value})}
                        />
                      </div>
                    </div>

                    <div className="input-container message">
                      <label htmlFor="message"><Trans>message</Trans><span
                          style={{fontSize: 'x-small'}}> (<Trans>optional</Trans>)</span></label>
                      <textarea name="message"
                                value={formData.message}
                                maxLength={250}
                                onChange={e => setFormData({...formData, message: e.target.value})}
                      >{formData.message}</textarea>
                    </div>
                    <div className="input-container-submit">
                      <input
                          className={'form-submit'}
                          type="submit"
                          aria-disabled={!isFormValid ? 'true' : 'false'}
                          value={t('submit')}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <section className={'upgrade-footer'}>
              <div className="container">
                {/*<h3>*/}
                {/*  Digilock Americas*/}
                {/*</h3>*/}
                {/*<div>*/}
                {/*  <p dangerouslySetInnerHTML={{__html: Settings.DIGILOCK_AMERICAS_ADDRESS}} />*/}
                {/*  <div className="liner" />*/}
                {/*  <p>*/}
                {/*    <strong><Trans>call_sales</Trans></strong> <br/>*/}
                {/*    <Trans>toll_free</Trans>*/}
                {/*    {Settings.PHONE_SALES_AMERICAS}*/}
                {/*  </p>*/}
                {/*</div>*/}
                <p>
                  <Link to={'/support/upgrader-program/upgrader-terms-conditions/'}>*<Trans>term_conditions_apply</Trans></Link>
                </p>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSupportGeneralFaqXlsxLockFunctionality {
      edges {
        node {
          id
            title
            content
        }
      }
    }
    allSupportGeneralFaqXlsxSetupProgramming {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxTroubleShooting {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxOrdersSupport {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  }
`;
